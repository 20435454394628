import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { IconButton, SvgSpriteConsumer, Tag } from '@pluxee-design-system/core';
import { FeeRow } from 'common/typography';
import { formatPrice, getPriceAndFees } from 'components/Products/PayoutCard/utils';
import useTranslations from 'i18n';
import { useCallback, useMemo, useState } from 'react';
import { CountryPayoutCardProps } from './types';

const CzPayoutCard = ({
  channel: {
    deliveryName,
    deliveryNameKey,
    frequencyIndividual,
    frequencyName,
    name,
    nameKey,
    priceList,
  },
  children,
  onlyProductName = false,
  productName,
  productNameKey,
}: CountryPayoutCardProps) => {
  const { t, tHtml } = useTranslations();
  const [isExpanded, setExpanded] = useState(true);
  const toggleExpanded = useCallback(() => setExpanded((p) => !p), [setExpanded]);
  const { price, fees, isSpecialOffer } = useMemo(() => getPriceAndFees(priceList), [priceList]);

  return (
    <Box width="100%">
      <Flex gap={4} justify="space-between">
        <Box>
          <HStack spacing={2}>
            <Text as="span" variant="subtitle.section">
              {onlyProductName
                ? t('product_details.products.*.product.name', productName, {
                    query: { where: 'product_details.products.*.product.code', is: productNameKey },
                  })
                : t(`product_details.payouts.*.${nameKey}.name`, name)}
            </Text>
            {(isSpecialOffer || frequencyIndividual) && (
              <Tag size="sm">{t('product_details.prices.specialOffer', 'Special offer')}</Tag>
            )}
          </HStack>
          <Text as="div" variant="subtitle.card" mt={1.5}>
            {tHtml(
              `product_details.payouts.*.${nameKey}.description`,
              'Text information about a bundle up to 2 lines',
            )}
          </Text>
        </Box>
        <Box>
          <HStack spacing={4}>
            {price && (
              <Text as="div" color="semantic.text.highlight">
                {formatPrice(price)}
              </Text>
            )}
            <IconButton
              aria-label="chevron"
              icon={
                <SvgSpriteConsumer size={24} svgId={isExpanded ? 'chevronUp24' : 'chevronDown24'} />
              }
              onClick={toggleExpanded}
              size="md"
              variant="icon"
            />
          </HStack>
        </Box>
      </Flex>
      {isExpanded && (
        <VStack align="stretch" spacing={2} mt={4}>
          {fees.map((fee) => (
            <FeeRow
              key={fee.feeCode}
              name={t(`product_details.feeTypes.${fee.feeCode}`, fee.feeCode)}
            >
              {formatPrice(fee)}
            </FeeRow>
          ))}
          {frequencyIndividual && frequencyName && (
            <FeeRow name={t('product_details.prices.payoutFrequency', 'Frequency')}>
              {frequencyName}
            </FeeRow>
          )}
          {deliveryNameKey && (
            <FeeRow name={t('product_details.prices.deliveryChannel', 'Delivery')}>
              {tHtml(`product_details.deliveryChannels.*.${deliveryNameKey}.name`, deliveryName)}
            </FeeRow>
          )}
          {children}
        </VStack>
      )}
    </Box>
  );
};

export default CzPayoutCard;
