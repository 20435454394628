import { Text, VStack } from '@chakra-ui/react';
import { FeeRow } from 'common/typography';
import { TranslateFn } from 'i18n';
import { formatPrice, PriceAndFees } from './utils';

interface BeOnlineFeesProps {
  t: TranslateFn;
  fees: PriceAndFees;
}

const BeOnlineFees = ({ fees: { feesByKey: fees }, t }: BeOnlineFeesProps) => (
  <VStack spacing={2} width="100%" align="flex-start">
    <Text as="div" variant="subtitle.section">
      {t('product_details.mediaTypes.online', 'Digital')}
    </Text>
    <FeeRow name={t('product_details.prices.onlineTrxFee', 'Transaction fee')}>
      {formatPrice(fees['onlinePrice'], '0')}
    </FeeRow>
  </VStack>
);

export default BeOnlineFees;
