import { useCallback, useLayoutEffect, useState } from 'react';

const useWindowPosition = () => {
  const windowClient = 'object' === typeof window;

  const getPosition = useCallback(() => (windowClient ? window.pageYOffset : 0), [windowClient]);

  const [position, setPosition] = useState(getPosition);

  useLayoutEffect(() => {
    if (!windowClient) {
      return;
    }
    function handleScroll() {
      setPosition(getPosition());
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [windowClient, getPosition]);

  return position;
};

export default useWindowPosition;
