import { Box, useImage } from '@chakra-ui/react';
import { XMark, XMarkStyles, XMarkVariants } from '@pluxee-design-system/core';
import { Product, ProductFull } from 'generated/models';
import useTranslations from 'i18n';

interface ProductBackgroundProps {
  product: Product | ProductFull;
  pictureWidth?: number;
  pictureHeight?: number;
}

// TODO: replace by Illustration when all icons included in library
const ProductBackground = ({
  pictureHeight = 96,
  product: { name, nameKey },
}: ProductBackgroundProps) => {
  const { t } = useTranslations();
  const altText = t('product_details.products.*.product.name', name, {
    query: { where: 'product_details.products.*.product.code', is: nameKey },
  });
  const backgroundUrl = t('product_details.products.*.product.picture.url', '', {
    query: { where: 'product_details.products.*.product.code', is: nameKey },
  });
  const heightPx = `${pictureHeight}px`;
  const isLoaded = useImage({ src: backgroundUrl }) === 'loaded';

  return (
    <Box
      width="100%"
      alignSelf="stretch"
      lineHeight={heightPx}
      height={heightPx}
      overflow="hidden"
      flex="1 1 0"
    >
      {isLoaded && (
        <Box
          as="img"
          src={backgroundUrl}
          title={altText}
          alt={altText}
          height={heightPx}
          objectFit="cover"
          objectPosition="top right"
        />
      )}
      {!isLoaded && (
        <Box
          width="100%"
          height="100%"
          position="relative"
          background="semantic.brand.secondary-dimmed"
          borderBottom="1px solid"
          borderBottomColor="semantic.brand.secondary"
          p={2}
        >
          <XMark
            style={XMarkStyles.OUTLINED}
            variant={XMarkVariants.ULTRA_GREEN}
            width="initial"
            height="100%"
            // @ts-ignore
            marginLeft="auto"
            marginRight={4}
          />
        </Box>
      )}
    </Box>
  );
};

export default ProductBackground;
