import { TagStatus } from '@pluxee-design-system/core';
import { ProductStatusEnum } from 'generated/models';
import { I18nProps, withTranslations } from 'i18n';

interface ProductStatusProps extends I18nProps {
  status: ProductStatusEnum;
}

export const getProductStatus = (status: ProductStatusEnum) => {
  switch (status) {
    case ProductStatusEnum.Adding:
    case ProductStatusEnum.Updating:
      return {
        variant: 'inProgress',
        tKey: 'global_texts.statuses.pending',
        tDefault: 'Pending',
      };
    case ProductStatusEnum.Active:
      return {
        variant: 'shipped',
        tKey: 'global_texts.statuses.active',
        tDefault: 'Active',
      };
    case ProductStatusEnum.Removing:
      return {
        variant: 'delay',
        tKey: 'global_texts.statuses.removing',
        tDefault: 'Removing',
      };
    default:
      return {
        variant: 'canceled',
        tKey: 'global_texts.statuses.failed',
        tDefault: 'Failed',
      };
  }
};

// TODO: create one component?
const ProductStatus = ({ status, t }: ProductStatusProps) => {
  if (!status) {
    return null;
  }
  
  const { tKey, tDefault, variant } = getProductStatus(status);
  return (
    <TagStatus
      ellipse
      text={t(tKey, tDefault)}
      // @ts-ignore
      variant={variant}
    />
  );
};

export default withTranslations(ProductStatus);
