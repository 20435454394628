import { Box, Flex, Spacer, Text } from '@chakra-ui/react';
import { Button, IconButton, Icons, SvgSpriteConsumer } from '@pluxee-design-system/core';
import { I18nProps, withTranslations } from 'i18n';

interface LocationsFooterProps extends I18nProps {
  removeProductText?: string;
  selectedLocations: number;
  onRemoveProduct?: () => void;
  onShowLocations?: () => void;
}

const LocationsFooter = ({
  removeProductText,
  selectedLocations,
  onRemoveProduct,
  onShowLocations,
  t,
}: LocationsFooterProps) => (
  <>
    {onShowLocations && (
      <Flex align="center" width="100%" cursor="pointer" onClick={onShowLocations}>
        <Box>
          <Text variant="title.mobileSection">
            {t(
              'products_portal.sidebarTexts.locationsWithSelectedProduct',
              'Locations using this product',
            )}
            :
          </Text>
          <Text variant="subtitle.card" color="semantic.text.tertiary" mt={1}>
            {selectedLocations}
          </Text>
        </Box>
        <Spacer />
        <Button
          rightIcon={<SvgSpriteConsumer size={24} svgId={Icons.ARROW_RIGHT24} />}
          size="md"
          variant="icon"
        >
          {t('products_portal.sidebarButtons.manageLocations', 'Manage')}
        </Button>
      </Flex>
    )}
    {onRemoveProduct && (
      <Flex align="center" width="100%" cursor="pointer" onClick={onRemoveProduct}>
        <Text variant="subtitle.card" color="semantic.text.tertiary">
          {removeProductText ??
            t('products_portal.sidebarButtons.remove', 'Remove product from contract')}
        </Text>
        <Spacer />
        <IconButton
          aria-label="trash"
          icon={<SvgSpriteConsumer size={24} svgId={Icons.TRASH24} />}
          size="md"
          variant="icon"
        />
      </Flex>
    )}
  </>
);

export default withTranslations(LocationsFooter);
