import { Box } from '@chakra-ui/react';
import { Product, ProductFull, ProductStatusEnum } from 'generated/models';
import ProductBackground from './ProductBackground';
import ProductLogo from './ProductLogo';
import ProductStatus from './ProductStatus';

interface ProductHeaderProps {
  product: Product | ProductFull;
  status?: ProductStatusEnum;
}

const ProductHeader = ({ product, status }: ProductHeaderProps) => (
  <Box height="96px" position="relative" width="100%">
    <ProductBackground product={product} />
    <Box
      position="absolute"
      background="semantic.surface.1"
      top={4}
      left={4}
      borderRadius="md"
      width="32px"
      height="32px"
      opacity={0.5}
    />
    <Box position="absolute" top={4} left={4} p={1} borderRadius="md">
      <ProductLogo product={product} />
    </Box>
    {status && (
      <Box position="absolute" bottom={2} left={4}>
        <ProductStatus status={status} />
      </Box>
    )}
  </Box>
);

export default ProductHeader;
