import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { IconButton, SvgSpriteConsumer } from '@pluxee-design-system/core';
import { LocationTypeEnum } from 'generated';
import useTranslations from 'i18n';
import { useCallback, useMemo, useState } from 'react';
import OnlineFees from './BeOnlineFees';
import PhysicalFees from './BePhysicalFees';
import { CountryPayoutCardProps } from './types';
import { getPriceAndFees } from './utils';

const BePayoutCard = ({
  channel: { priceTierName, priceTierNameKey, priceList },
  children,
  locationType,
  onlyProductName = false,
  productName,
  productNameKey,
}: CountryPayoutCardProps) => {
  const { t, tQueryKey } = useTranslations();
  const [isExpanded, setExpanded] = useState(true);
  const toggleExpanded = useCallback(() => setExpanded((p) => !p), [setExpanded]);
  const fees = useMemo(() => getPriceAndFees(priceList), [priceList]);
  const tQueryProduct = tQueryKey('product_details.products.*.product', {
    where: 'product_details.products.*.product.code',
    is: productNameKey,
  });
  const tQueryTier = tQueryKey(`${tQueryProduct}.tiers.*.tier`, {
    where: `${tQueryProduct}.tiers.*.tier.code`,
    is: priceTierNameKey,
  });
  const showOnlineFees =
    locationType === undefined ||
    locationType === LocationTypeEnum.Online ||
    locationType === LocationTypeEnum.Marketplace;
  const showPhysicalFees = locationType === undefined || locationType === LocationTypeEnum.Physical;

  return (
    <Box width="100%">
      <Flex gap={4} justify="space-between">
        <Box>
          <Text as="div" variant="subtitle.section">
            {onlyProductName
              ? t(`${tQueryProduct}.name`, productName)
              : t(`${tQueryTier}.name`, priceTierName)}
          </Text>
          <Text as="div" variant="subtitle.card" mt={1.5}>
            {t(`${tQueryTier}.description`, priceTierName)}
          </Text>
        </Box>
        <Box>
          <IconButton
            aria-label="chevron"
            icon={
              <SvgSpriteConsumer size={24} svgId={isExpanded ? 'chevronUp24' : 'chevronDown24'} />
            }
            onClick={toggleExpanded}
            size="md"
            variant="icon"
          />
        </Box>
      </Flex>
      {isExpanded && (
        <VStack align="stretch" spacing={2} mt={4}>
          <Flex direction={{ base: 'column', md: 'row' }} gap={6} align="flex-start">
            {showPhysicalFees && <PhysicalFees fees={fees} t={t} />}
            {showOnlineFees && <OnlineFees fees={fees} t={t} />}
          </Flex>
          {children}
        </VStack>
      )}
    </Box>
  );
};

export default BePayoutCard;
