import { MutableRefObject, useLayoutEffect } from 'react';
import useWindowPosition from 'data/useWindowPosition';

const OFFSET = 80;

const useDrawerPosition = (wrapperRef: MutableRefObject<HTMLElement | null>) => {
  const position = useWindowPosition();

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      if (position <= OFFSET) {
        wrapperRef.current.style.top = `${OFFSET - position}px`;
        wrapperRef.current.style.height = `calc(100% - ${OFFSET - position}px)`;
      } else {
        wrapperRef.current.style.top = '0';
        wrapperRef.current.style.height = '100%';
      }
    }
  }, [wrapperRef, position]);
  
  return position;
};

export default useDrawerPosition;
