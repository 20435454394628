import { SelectOption } from '@pluxee-design-system/core';
import { PayoutChannel, PayoutFrequency } from 'generated/models';

export const getSelectedPayout = (
  payoutList?: PayoutFrequency[] | null,
  selectedPayoutId?: string,
) =>
  payoutList?.reduce<PayoutChannel | undefined>(
    (prev, payout) =>
      prev || payout.channelList.find((item) => item.payoutUid === selectedPayoutId),
    undefined,
  );

export const preselectTab = (
  tabs: SelectOption[],
  payouts: Record<string, PayoutFrequency>,
  selectedPayoutUid?: string | null,
) =>
  tabs.find((tab) =>
    Boolean(payouts[tab.value].channelList.find((item) => item.payoutUid === selectedPayoutUid)),
  ) || tabs[0];
