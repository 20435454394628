import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import {
  Button,
  InputVariant,
  Notification,
  PassiveVariants,
  Select,
  SelectOption,
} from '@pluxee-design-system/core';
import { RadioGroupControl } from 'common/forms';
import { warnEcoPassModalParams } from 'components/Products/modals';
import { isEcoPass } from 'components/Products/PayoutCard/utils';
import { payoutSchema } from 'config/schemas/products';
import useModal from 'context/ModalContext';
import { FormikProps, withFormik } from 'formik';
import { PayoutChannel, PayoutFrequency } from 'generated/models';
import useTranslations from 'i18n';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { SingleValue } from 'react-select';
import isKeyEmpty from 'utils/isKeyEmpty';
import PayoutCard from '../PayoutCard';
import { PartnerAccountsDict, PayoutsFormValues } from '../types';
import { preselectTab } from './utils';

interface PayoutsStepProps {
  children?: ReactNode;
  isEditable: boolean;
  isSelectable: boolean;
  isSubmittable: boolean;
  isStepValid: boolean;
  onSelectPayout: (payout: PayoutChannel, accountNumber?: string, defaultAccount?: string) => void;
  onSubmit: (values: PayoutsFormValues) => void;
  onShowLocations: () => void;
  partnerAccounts: PartnerAccountsDict;
  payouts: Record<string, PayoutFrequency>;
  productNameKey?: string;
  selectedPayout?: PayoutChannel;
  showWarning?: boolean;
  withLocations: boolean;
}

const PayoutsStep = ({
  children,
  isEditable,
  isSelectable,
  isSubmittable,
  isStepValid,
  onSelectPayout,
  payouts,
  productNameKey,
  selectedPayout,
  showWarning,
  // formik
  isValid,
  setValues,
  submitForm,
  values,
  errors,
}: PayoutsStepProps & FormikProps<PayoutsFormValues>) => {
  const showModal = useModal();
  const { t, tHtml } = useTranslations();
  const tabs = useMemo<SelectOption[]>(
    () =>
      Object.keys(payouts)
        .filter((key) => payouts[key].channelList.length > 0)
        .sort((keyA, keyB) => (payouts[keyA].weight ?? 0) - (payouts[keyB].weight ?? 0))
        .map((tab) => ({
          value: tab,
          label: t(`product_details.payoutFrequency.${tab}`, payouts[tab]?.name),
        })),
    [payouts, t],
  );
  const [selectedTab, setSelectedTab] = useState(() =>
    preselectTab(tabs, payouts, selectedPayout?.payoutUid),
  );
  const handleSelectTab = useCallback(
    (tab: SingleValue<SelectOption>) => tab && setSelectedTab(tab),
    [setSelectedTab],
  );
  const handleSelectPayout = useCallback(
    async (newValue: string) => {
      const newPayout = payouts[selectedTab?.value]?.channelList?.find(
        (l) => l.payoutUid === newValue,
      );
      if (newPayout) {
        const isEco = isEcoPass(newPayout);
        if ((isEco && (await showModal(warnEcoPassModalParams(t, tHtml)))) || !isEco) {
          onSelectPayout(newPayout, values.partnerAccounts[String(newPayout.parameterCode)]);
          setValues((prevValues) => ({
            ...prevValues,
            payout: newValue,
            parameterCode: newPayout.parameterCode ?? '',
          }));
        }
      }
    },
    [
      onSelectPayout,
      payouts,
      selectedTab?.value,
      setValues,
      showModal,
      values.partnerAccounts,
      t,
      tHtml,
    ],
  );

  useEffect(
    () => setSelectedTab(preselectTab(tabs, payouts, selectedPayout?.payoutUid)),
    [tabs, setSelectedTab, payouts, selectedPayout?.payoutUid],
  );

  return (
    <>
      <Flex gap={10} direction="column">
        <Text
          as="div"
          variant="subtitle.desktopMain"
          mt={2}
          color="semantic.text.tertiary"
          align="center"
        >
          {tHtml('product_details.products.*.product.descriptionInSidebar', '', {
            query: { where: 'product_details.products.*.product.code', is: productNameKey },
          })}
        </Text>
        <Box>
          <Text variant="title.desktopCard">
            {t('products.sidebarTexts.header', 'Select an offer')}
          </Text>
          <Text variant="subtitle.card" color="semantic.text.tertiary" mt={1} mb={4}>
            {t(
              'products.sidebarTexts.selectOfferDescription',
              'Choose the pricing option for your product',
            )}
          </Text>
          {showWarning && (
            <Notification
              description={t(
                'products.sidebarTexts.informativeOptionsNote',
                'The following options are only informative. The payout channel is defined by the mean of delivery of the vouchers and the payout channel you choose for each of the remittance.',
              )}
              isClosable={false}
              withBorder={false}
              withoutShadow
              variant={PassiveVariants.PASSIVE_INFORMATION}
            />
          )}
          <RadioGroupControl name="payout" mt={6} onChange={handleSelectPayout}>
            {tabs.length > 0 && !isKeyEmpty(tabs[0]?.value) && (
              <Select
                mandatory
                name="frequency"
                id="ProductDrawer_frequency"
                placeholder={t('product_details.prices.payoutFrequency', 'Frequency')}
                title={t('product_details.prices.payoutFrequency', 'Frequency')}
                options={tabs}
                value={selectedTab}
                onChange={handleSelectTab}
                variant={tabs.length > 1 ? undefined : InputVariant.READONLY}
              />
            )}
            {payouts[selectedTab?.value]?.channelList?.map((channel) => (
              <PayoutCard
                key={channel.payoutUid}
                channel={channel}
                isDisabled={!isSelectable || !isEditable}
                isSelectable={isSelectable}
                isSelected={channel === selectedPayout}
                hasError={
                  Boolean(errors.payout) ||
                  Boolean(errors.partnerAccounts?.[String(channel.parameterCode)])
                }
                productNameKey={productNameKey}
              />
            ))}
          </RadioGroupControl>
        </Box>
      </Flex>
      <VStack
        position="sticky"
        spacing={6}
        bottom={0}
        background="semantic.surface.1"
        py={6}
        mb={-6}
      >
        {children}
        {isSubmittable && (
          <Button
            onClick={submitForm}
            isDisabled={!isValid || !isStepValid}
            type="button"
            id="PayoutsStep_applyButton"
            variant="primaryFilled"
          >
            {t('products.sidebarButtons.apply', 'Apply')}
          </Button>
        )}
      </VStack>
    </>
  );
};

export default withFormik<PayoutsStepProps, PayoutsFormValues>({
  mapPropsToValues: ({ partnerAccounts, payouts, selectedPayout }) => ({
    payout: selectedPayout?.payoutUid ?? '',
    parameterCode: selectedPayout?.parameterCode ?? '', // helper
    partnerAccounts: {
      ...Object.values(payouts).reduce((result, { channelList }) => {
        channelList.forEach(({ parameterCode }) => {
          if (parameterCode) result[String(parameterCode)] = '';
        });
        return result;
      }, {} as PartnerAccountsDict),
      ...(selectedPayout?.parameterCode ? { [selectedPayout?.parameterCode]: '' } : {}),
      ...partnerAccounts,
    },
  }),
  handleSubmit: (values, { props }) => props.onSubmit(values),
  validationSchema: ({ isSelectable }: PayoutsStepProps) => payoutSchema(isSelectable),
  enableReinitialize: false,
  displayName: 'PayoutsStep',
})(PayoutsStep);
