import { Text, VStack } from '@chakra-ui/react';
import { FeeRow } from 'common/typography';
import { TranslateFn } from 'i18n';
import { formatPrice, PriceAndFees } from './utils';

interface BePhysicalFeesProps {
  t: TranslateFn;
  fees: PriceAndFees;
}

const BePhysicalFees = ({
  fees: {
    price,
    feesByKey: { onlinePrice, ...fees },
  },
  t,
}: BePhysicalFeesProps) => {
  const replaceFees = {
    trxFee: formatPrice(price, '0'),
    trxCutOff: formatPrice(fees['trxCutOff'], '0'),
    trxCostAboveCutOff: formatPrice(fees['trxCostAboveCutOff'], '0'),
    trxMinCost: formatPrice(fees['trxMinCost'], '0'),
    monthlyFee: formatPrice(fees['monthlyFee'], '0'),
    activationFee: formatPrice(fees['activationFee'], '0'),
  };
  const hasOnlyPrice = Object.keys(fees).length === 0; // price is not included

  return (
    <VStack spacing={2} width="100%" align="flex-start">
      <Text as="div" variant="subtitle.section">
        {t('product_details.mediaTypes.physical', 'Physical')}
      </Text>
      <FeeRow
        name={
          hasOnlyPrice
            ? t('product_details.prices.physicalTrxFee', 'Transaction fee')
            : t('product_details.prices.trxFee', 'Transaction fee', replaceFees)
        }
      >
        {formatPrice(fees['trxCutOff'] ? fees['trxCostAboveCutOff'] : price, '0')}
      </FeeRow>
      {fees['trxCutOff']?.fee > 0 && (
        <FeeRow
          name={t('product_details.prices.trxFeeCutOff', 'Cost per transaction', replaceFees)}
        >
          {formatPrice(price, '0')}
        </FeeRow>
      )}
      {fees['monthlyFee'] && (
        <FeeRow name={t('product_details.prices.monthlyFee', 'Monthly fee', replaceFees)}>
          {formatPrice(fees['monthlyFee'], '0')}
        </FeeRow>
      )}
      {fees['activationFee'] && (
        <FeeRow name={t('product_details.prices.activationFee', 'Activation fee', replaceFees)}>
          {formatPrice(fees['activationFee'], '0')}
        </FeeRow>
      )}
    </VStack>
  );
};

export default BePhysicalFees;
