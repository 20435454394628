import { IllustrationHeaderVariant, Illustrations } from '@pluxee-design-system/core';
import Illustration from 'common/Illustration';
import { ShowModalParams } from 'context/ModalContext';
import { Product, ProductFull } from 'generated/models';
import { TranslateFn, TranslateHtmlFn } from 'i18n';

const tReplace = (
  key: string,
  defaultValue: string,
  t: TranslateFn,
  product: Product | ProductFull,
) =>
  t(key, defaultValue, {
    productName: t('product_details.products.*.product.name', product.name, {
      query: { where: 'product_details.products.*.product.code', is: product.nameKey },
    }),
  });

/**
 * Deleting the product
 */
export const deleteModalParams = (
  t: TranslateFn,
  product: Product | ProductFull,
): ShowModalParams => ({
  confirmText: t('global_texts.buttons.confirm', 'Confirm'),
  cancelText: t('global_texts.buttons.cancel', 'Cancel'),
  title: tReplace(
    'modals.products.dialogDelete.header',
    'Are you sure you want to remove %productName% from contract?',
    t,
    product,
  ),
  text: tReplace(
    'modals.products.dialogDelete.description',
    'The product will be also removed from all locations and terminals, when the product is activated.',
    t,
    product,
  ),
});

/**
 * Changing already assigned product
 */
export const changeModalParams = (
  t: TranslateFn,
  product: Product | ProductFull,
): ShowModalParams => ({
  confirmText: t('global_texts.buttons.confirm', 'Confirm'),
  cancelText: t('global_texts.buttons.cancel', 'Cancel'),
  title: tReplace(
    'modals.products.dialogUpdate.header',
    'You are about to change %productName% configuration',
    t,
    product,
  ),
  text: tReplace(
    'modals.products.dialogUpdate.description',
    'The product configuration will be applied to all terminals in the selected location(s). ' +
      'If you want to change it, you may change this in Locations configuration, once the update process will be completed in Pluxee systems.',
    t,
    product,
  ),
});

/**
 * Adding new product to a contract
 */
export const addModalParams = (
  t: TranslateFn,
  product: Product | ProductFull,
): ShowModalParams => ({
  confirmText: t('global_texts.buttons.confirm', 'Confirm'),
  cancelText: t('global_texts.buttons.cancel', 'Cancel'),
  title: tReplace(
    'modals.products.dialogAdd.header',
    'You are about to add %productName% to your contract',
    t,
    product,
  ),
  text: tReplace(
    'modals.products.dialogAdd.description',
    'The product will be added to all terminals in the selected location(s). ' +
      'If you want to change it, you may change this in Locations configuration, once the adding will be completed in Pluxee systems.',
    t,
    product,
  ),
});

/**
 * Warning about selecting an Eco Pass payout
 */
export const warnEcoPassModalParams = (t: TranslateFn, tHtml: TranslateHtmlFn): ShowModalParams => {
  const illustrationId = t('product_details.ecoModal.illustration', '');

  return {
    confirmText: t('global_texts.buttons.confirm', 'Confirm'),
    cancelText: t('global_texts.buttons.cancel', 'Cancel'),
    title: t('product_details.ecoModal.header', 'You are about to add Pluxee Eco to your contract'),
    text: tHtml(
      'product_details.ecoModal.description',
      'Make sure you are compliant with Pluxee Eco rules and have indeed product that goes into eco category.',
    ),
    ignoreClickOutside: true,
    hideCloseButton: true,
    illustration: illustrationId
      ? {
          variant: IllustrationHeaderVariant.INFO,
          illustration: (
            <Illustration illustrationId={illustrationId as Illustrations} width={24} />
          ),
        }
      : undefined,
  };
};
